<template>
  <div>
    <Header></Header>
    <div class="jsfn1">
      <img class="jsfnA1" src="@/assets/xwzx.png" alt="" />
      <div class="jsfnB1">新闻快讯</div>
      <div class="jsfnC1">NEWS FLASH</div>
    </div>
    <div class="body">
      <div class="third">
        <div class="xwrb">新闻热榜</div>
        <div class="sRight">
          <div class="solution">HOT LIST</div>
          <div class="more">
            <!-- <div class="gdal">更多新闻></div> -->
          </div>
        </div>
        <div
          class="thirdContent thirdBorder"
          v-for="(item, index) in hotList"
          :key="index"
        >
          <div class="pleftImg">
            <img class="leftImg" :src="baseURL+item.fileSrc" alt=""   @click="goMore(item)" style="cursor: pointer"/>
            <div v-if="(index+1) === 1" style="background-color: #FF6000" class="titleToLeft">
              {{index+1}}
            </div>
            <div v-if="(index+1) === 2" style="background-color: #FF8001" class="titleToLeft">
              {{index+1}}
            </div>
            <div v-if="(index+1) === 3" style="background-color: #FFC501" class="titleToLeft">
              {{index+1}}
            </div>
          </div>
          <div class="rightCon">
            <div class="title" style="cursor: pointer">{{ item.title }}</div>
            <div class="tCon">{{ item.introduce }}</div>
            <div class="date">{{ item.sendTime }}</div>
            <div class="dateMore" @click="goMore(item)">查看详情></div>
          </div>
        </div>
      </div>
      <div
        class="bodyBottomContent"
        v-for="(item, index) in newsList"
        :key="index"
      >
        <div
          class="bodyBotCon"
          v-for="(item1, index1) in item.dataList1"
          :key="index1"
          @click="goMore(item1)"
        >
          <div class="xwzxk">
            <div class="xwzxkImg"><img v-if="item1.fileSrc" class="xwzxk1" :src="baseURL+item1.fileSrc" alt="" /></div>
            <div class="xwzxkTime">{{ (item1.sendTime).substr(0,10) }}</div>
          </div>
          <div class="bodyBotConFont">{{ item1.title }}</div>
          <div class="bodyBotConFont1">{{ item1.introduce }}</div>
        </div>
      </div>
      <div class="block">
<!--        <el-pagination-->
<!--          @size-change="handleSizeChange"-->
<!--          @current-change="handleCurrentChange"-->
<!--          :current-page.sync="currentPage2"-->
<!--          :page-sizes="[8, 16, 24, 32]"-->
<!--          :page-size="100"-->
<!--          layout="sizes, prev, pager, next"-->
<!--          :total="32"-->
<!--        >-->
<!--        </el-pagination>-->
        <pagination
          style="margin-left: 15px"
          v-show="total>0"
          :total="total"
          :page.sync="pageNum"
          :limit.sync="pageSize"
          @pagination="getList"
        />
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/header.vue";
import Footer from "@/views/footer.vue";
import {getNewsNoticeHomePage, getNewsNoticeList} from "../../api/open";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_API,
      third: [
        {
          img: require("@/assets/tone.png"),
          title: "国庆节放假通知！",
          content:
            "融媒体特别节目《二十大时光》带您领略大美中国。通过一个个具体的地点，让您对二十大报告中提到的重要内容有直观的感受。",
          date: "2022-10-19 15:32",
        },
        {
          img: require("@/assets/ttwo.png"),
          title: "端午节放假通知！",
          content:
            "融媒体特别节目《二十大时光》带您领略大美中国。通过一个个具体的地点，让您对二十大报告中提到的重要内容有直观的感受。",
          date: "2022-10-19 15:32",
        },
        {
          img: require("@/assets/tthree.png"),
          title: "春节放假通知！",
          content:
            "融媒体特别节目《二十大时光》带您领略大美中国。通过一个个具体的地点，让您对二十大报告中提到的重要内容有直观的感受。",
          date: "2022-10-19 15:32",
        },
      ],
      dataList: [
        {
          dataList1: [
            {
              img: require("@/assets/xwzxk1.png"),
              title: "三道防线构筑安全铁闸…",
              content: "住房公积金政策是保障公民助局权实现“住有所居 民有所依”…",
            },
            {
              img: require("@/assets/xwzxk2.png"),
              title: "关于第三方CNAS验收评…",
              content:
                "项目验收、课题验收或科研成果鉴定时负责人最害怕收到的消…",
            },
            {
              img: require("@/assets/xwzxk3.png"),
              title: "三道防线构筑安全铁闸…",
              content: "浙江省改革突破奖名单公布",
            },
            {
              img: require("@/assets/xwzxk4.png"),
              title: "三道防线构筑安全铁闸…",
              content: "住房公积金政策是保障公民助局权实现“住有所居 民有所依”…",
            },
          ],
        },
        {
          dataList1: [
            {
              img: require("@/assets/xwzxk4.png"),
              title: "三道防线构筑安全铁闸…",
              content: "住房公积金政策是保障公民助局权实现“住有所居 民有所依”…",
            },
            {
              img: require("@/assets/xwzxk3.png"),
              title: "三道防线构筑安全铁闸…",
              content: "浙江省改革突破奖名单公布",
            },
            {
              img: require("@/assets/xwzxk1.png"),
              title: "三道防线构筑安全铁闸…",
              content: "住房公积金政策是保障公民助局权实现“住有所居 民有所依”…",
            },
            {
              img: require("@/assets/xwzxk2.png"),
              title: "关于第三方CNAS验收评…",
              content:
                "项目验收、课题验收或科研成果鉴定时负责人最害怕收到的消…",
            },
          ],
        },
      ],
      hotList:[],
      newsList:[],
      pageNum:1,
      pageSize:8,
      total:0,
    };
  },
  mounted() {
    this.getHotList()
    this.getList()
  },
  methods: {
    getHotList(){
      getNewsNoticeHomePage({}).then(res=>{
        this.hotList = res.rows
      })
    },
    getList(){
      getNewsNoticeList({pageNum:this.pageNum,pageSize:this.pageSize}).then(res=>{
        this.total = res.total
        this.newsList = res.rows
      })
    },
    goMore(row) {
      this.$router.push({path:"pressCenter2",query:{id:row.id} });
    }
  }
};
</script>

<style scoped>
.leftImg{
  width: 240px;
  height: 140px;
}
.xwrb {
  font-size: 24px;
  padding: 60px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.sRight {
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
}
.solution {
  font-size: 24px;
  color: #bfbfbf;
  margin-left: 32px;
  letter-spacing: 1px;
}
.more {
  display: flex;
}
.gdal {
  margin-right: 4px;
  font-size: 14px;
  color: #7d7d7d;
}
.thirdContent {
  display: flex;
  margin: 16px 0px 0 32px;
  border-bottom: 1px solid #d8d8d8;
}
.thirdBorder {
  border: none;
}
.leftImg {
  margin: 0 24px 16px 0;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.rightCon {
  width: 100%;
  height: 156px;
  position: relative;
}
.title {
  width: 890px;
  font-size: 24px;
  margin-bottom: 16px;
  letter-spacing: 1px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.tCon {
  width: 890px;
  margin-right: 16px;
  color: #595959;
  letter-spacing: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.date {
  color: #595959;
  position: absolute;
  bottom: 16px;
  letter-spacing: 1px;
}
* {
  margin: 0;
  padding: 0;
}
.lbt {
  width: 100%;
}
.body {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;
  background: #fff !important;
}
.bodyBottomContent {
  margin-top: 40px;
  display: flex;
}
.bodyBotCon {
  cursor: pointer;
  margin-left: 32px;
  width: 260px;
}
.xwzxk1 {
  height: 200px;
  width: 260px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.bodyBotConFont {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 4px 20px 4px 0;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.bodyBotConFont1 {
  color: #7d7d7d;
  letter-spacing: 1px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.block {
    margin: 40px 32px 0 0;
    display: flex;
    justify-content: end;
}
.jsfn1 {
  position: relative;
}
.jsfnA1 {
  width: 100%;
}
.jsfnB1 {
  position: absolute;
  top: 200px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 60px;
  color: #fff;
  line-height: 33px;
  letter-spacing: 1px;
}
.jsfnC1 {
  position: absolute;
  top: 280px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 24px;
  color: #bfbfbf;
  line-height: 33px;
  letter-spacing: 1px;
}
.jsfnD1 {
  position: absolute;
  top: 360px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 40px;
  letter-spacing: 1px;
}
.dateMore {
  color: #595959;
  position: absolute;
  bottom: 16px;
  right: 24px;
  letter-spacing: 1px;
  cursor: pointer;
}
.titleToLeft{
  text-align:center;
  width: 25px;
  height: 25px;
  background-color: red;
  margin-top: -160px;
  line-height: 25px;
  position: absolute;
  color: white;
  border-bottom-right-radius: 10px;
}
.xwzxk {
  position: relative;
}
.xwzxkTime {
  position: absolute;
  bottom: 8px;
  right: 16px;
  color: #fff;
}
</style>